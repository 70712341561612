import React from "react"
import { css } from "@emotion/core"
// import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"
import Header from "./header"
import Footer from "./footer"

export default ({ children }) => {
  return (
  <div className="content"
    css={css`
      padding: ${rhythm(1.5)};
      padding-top: ${rhythm(1.5)};
    `}
  >
    <Header />
    {children}
    <Footer />
  </div>
  )
}
/** @jsx jsx */
import { jsx } from 'theme-ui'
// import React from "react"
// import { css } from "@emotion/core"
// import { Flex } from "@theme-ui/components"
import { useStaticQuery } from "gatsby"
import { Link } from 'theme-ui'
// import { rhythm } from "../utils/typography"
import "./header.scss"

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <header>
      <div
        sx={{
          boxSizing: `border-box`,
          display: `flex`,
          
          alignItems: `center`,
          justifyContent: `space-between`,
          mt: 3,
          flexFlow: `wrap`,
        }}
      >
        <div className="internal">
          <Link
            href={`/`}
            aria-label={`Ervin Ebalo - Back to home`}
            sx={{ color: `heading`, textDecoration: `none` }}
            className="title"
          >
            <span sx={{ my: 0, fontWeight: `medium`, fontSize: [1,2] }}>
              Ervin Ebalo
            </span>
          </Link>
        </div>
        <div className="external">
          <Link href={`https://www.linkedin.com/in/ervinebalo/`} sx={{ color: `heading`, textDecoration: `none` }}>LinkedIn</Link>
          <Link href={`/about/`} sx={{ color: `heading`, textDecoration: `none` }}>About</Link>
        </div>
      </div>
    </header>

  )
}
export default Header
/** @jsx jsx */
import { jsx } from 'theme-ui'

const Footer = () => {
  return (
    <footer
      sx={{
        mt: 5,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        variant: 'styles.footer',
      }}>
      <div sx={{ p: 2 }}>© 2020 Ervin Ebalo</div>
    </footer>
  )
}
export default Footer